import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="grids--answers-grid"
export default class extends Controller {
  static values = {
    url: String,
    questionId: Number,
    baseRubriekId: Number,
    hideEditCommand: { type: Boolean, default: false },
    hideDestroyCommand: { type: Boolean, default: false }
  }
  actionColumnIndex;
  commandButtonWidth = 50;
  gridConfig = {
    columnMenu: {
      filterable: false,
      sortable: false
    },
    columns: [
      {
        field: 'id',
        type: 'number',
        title: 'ID'
      },
      {
        field: 'answer_text',
        type: 'string',
        encoded: false,
        title: 'Antwoord'
      },
      {
        field: 'rubriek_id',
        type: 'number',
        title: 'Rubriek ID',
        hidden: true
      },
      {
        field: 'rubriek_versie_id',
        type: 'number',
        title: 'Rubriekversie ID',
        hidden: true
      },
      {
        field: 'rubriek_nummer',
        type: 'string',
        title: 'Rubrieknummer'
      },
      {
        field: 'is_eind_rubriek',
        type: 'boolean',
        title: 'Eindrubriek'
      },
      {
        field: 'created_at',
        type: 'date',
        format: '{0:dd/MM/yyyy HH:mm:ss}',
        title: 'Created',
        hidden: true
      },
      {
        field: 'updated_at',
        type: 'date',
        format: '{0:dd/MM/yyyy HH:mm:ss}',
        title: 'Updated',
        hidden: true
      }
    ],
    dataSource: {
      pageSize: 25,
      schema: {
        data: "answers",
        model: {
          id: "id",
          fields: {
            id: { type: "number" },
            answer_text: { type: "string" },
            rubriek_id: { type: "number" },
            rubriek_versie_id: { type: "number" },
            rubriek_nummer: { type: "string" },
            is_eind_rubriek: { type: "boolean" },
            created_at: { type: "date" },
            updated_at: { type: "date" },
            uri: { type: "string" },
            can_edit: { type: "boolean" },
            can_destroy: { type: "boolean" }
          }
        },
        parse: function(response) {
          response.warnings.forEach(function(warning) {
            this.warnings.push(warning);
          }.bind(this));
          return {answers: response.answers, total: response.total};
        }.bind(this),
        total: "total"
      },
      serverFiltering: true,
      serverPaging: true,
      serverSorting: true,
      transport: {
        // destroy: {
        //   cache: false,
        //   dataType: 'json',
        //   type: 'DELETE',
        //   url: '/admin/answers'
        // },
        parameterMap: function(data, type) {
          switch(type) {
            // case "destroy":
            case "read":
              if (data.filter) {
                data.filter.filters = data.filter.filters.map((filter) => {
                  return {
                    field: filter.field,
                    operator: filter.operator,
                    value: (filter.field == 'created_at' || filter.field == 'updated_at' ? kendo.toString(filter.value, 'yyyy-MM-ddTHH:mm:sszzz') : filter.value)
                  };
                });
              }
              return data
          }
        },
        read: {
          cache: false,
          data: {
            question_id: this.questionIdValue,
            base_rubriek_id: this.baseRubriekIdValue
          },
          dataType: 'json',
          url: this.urlValue
        }
      },
      change: function(e) {
        // const data = this.data();
        // const warnings= data.warnings;
        const element = $('#answer-warnings');
        element.empty();
        this.warnings.forEach((warning) => {
          switch(warning.type) {
            case 1:
              element.append(`<div class="alert alert-danger">Antwoord voor ontbrekende rubriek ${warning.rubriek_nummer}. <a href="#" class="alert-link" data-action="click->grids--answers-grid#fixInvalidAnswer" data-grids--answers-grid-action-url-param="${warning.action_url}" data-grids--answers-grid-action-method-param="${warning.action_method}" data-grids--answers-grid-answer-id-param="${warning.answer_id}">Fix problem</a>.</div>`);
              break;
            case 2:
              element.append(`<div class="alert alert-danger">Antwoord voor rubriek ${warning.rubriek_nummer} is niet bijgewerkt. <a href="#" class="alert-link" data-action="click->grids--answers-grid#fixInvalidChangedAnswer" data-grids--answers-grid-action-url-param="${warning.action_url}" data-grids--answers-grid-action-method-param="${warning.action_method}">Fix problem</a>.</div>`);
              break;
            case 3:
              element.append(`<div class="alert alert-danger">Ontbrekend antwoord voor rubriek ${warning.rubriek.rubrieknummer}. <a href="#" class="alert-link" data-action="click->grids--answers-grid#fixMissingAnswer" data-grids--answers-grid-action-url-param="${warning.action_url}" data-grids--answers-grid-action-method-param="${warning.action_method}" data-grids--answers-grid-question-id-param="${warning.question_id}" data-grids--answers-grid-rubriek-id-param="${warning.rubriek.id}" data-grids--answers-grid-rubriek-versie-id-param="${warning.rubriek.version_id}" data-grids--answers-grid-rubriek-nummer-param="${warning.rubriek.rubrieknummer}" data-grids--answers-grid-rubriek-description-param="${warning.rubriek.description}" data-grids--answers-grid-is-eind-rubriek-param="${warning.rubriek.is_eind_rubriek}">Fix problem</a>.</div>`);
              break;
          }
        });
      }.bind(this)
    },
    // editable: true,
    filterable: {
      mode: "row"
    },
    groupable: false,
    loaderType: "skeleton",
    pageable: true,
    reorderable: true,
    resizable: true,
    sortable: true
  }
  warnings = [];
  initialize() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;

    if (!this.hideEditCommandValue) this.addEditCommand();
    if (!this.hideDestroyCommandValue) this.addDestroyCommand();

    this.grid = $(this.element.querySelector("#answers-grid")).kendoGrid(this.gridConfig).data("kendoGrid");

    $(this.element.querySelector("#answers-grid")).kendoTooltip({
      filter: '.k-grid-edit-button',
      content: 'wijzig'
    }).data("kendoTooltip");
    $(this.element.querySelector("#answers-grid")).kendoTooltip({
      filter: '.k-grid-destroy-button',
      content: 'verwijder'
    }).data("kendoTooltip");
  }

  fixInvalidAnswer(event) {
    event.preventDefault();
    const grid = this.element.data('kendoGrid');
    $.ajax({
      url: event.params.actionUrl,
      dataType: 'json',
      headers: {
        'accept': 'application/json'
      },
      type: event.params.actionMethod,
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: function(data, textStatus, jqXHR) {
        // const rowUid = grid.dataItems().filter((item) => item.id == event.params.answer_id).uid;
        // grid.removeRow(`tr[data-uid='${rowUid}`);
        grid.dataSource.fetch();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        const response = JSON.parse(jqXHR.responseText);
        kendo.alert(response.message);
      }
    });
  }

  fixInvalidChangedAnswer(event) {
    event.preventDefault();
    const grid = this.element.data('kendoGrid');
    $.ajax({
      url: event.params.actionUrl,
      dataType: 'json',
      headers: {
        'accept': 'application/json'
      },
      type: event.params.actionMethod,
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: function(data, textStatus, jqXHR) {
        grid.dataSource.fetch();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        const response = JSON.parse(jqXHR.responseText);
        kendo.alert(response.message);
      }
    });
  }

  fixMissingAnswer(event) {
    event.preventDefault();
    const grid = this.grid;
    const data = {
      question_id: event.params.questionId,
      answer_text: event.params.rubriekDescription,
      rubriek_id: event.params.rubriekId,
      rubriek_versie_id: event.params.rubriekVersieId,
      rubriek_nummer: event.params.rubriekNummer,
      is_eind_rubriek: event.params.isEindRubriek
    };
    $.ajax({
      url: event.params.actionUrl,
      contentType: 'application/json',
      data: JSON.stringify(data),
      dataType: 'json',
      headers: {
        'accept': 'application/json'
      },
      type: event.params.actionMethod,
      beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
      success: function(data, textStatus, jqXHR) {
        grid.dataSource.fetch();
      },
      error: function(jqXHR, textStatus, errorThrown) {
        const response = JSON.parse(jqXHR.responseText);
        kendo.alert(response.message);
      }
    });
  }

  editAnswer(event) {
    event.preventDefault();
    const grid = this.element.data('kendoGrid');
    const rowData = this.dataItem($(event.target).closest("tr"));
    $("#edit-answer-dialog").kendoDialog({
      actions: [
        {
          action: function(e) {
            dialog.close();
          },
          text: "Annuleer"
        },
        {
          action: function(e) {
            const data = {
              answer_text: CKEDITOR.instances['answer_answer_text'].getData()
            };
            $.ajax({
              url: `${rowData.uri}.json`,
              contentType: 'application/json',
              data: JSON.stringify(data),
              dataType: 'json',
              headers: {
                'accept': 'application/json'
              },
              type: 'PUT',
              beforeSend: function(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')) },
              success: function(data, textStatus, jqXHR) {
                rowData.answer_text = CKEDITOR.instances['answer_answer_text'].getData();
                grid.refresh();
              },
              error: function(jqXHR, textStatus, errorThrown) {
                const response = JSON.parse(jqXHR.responseText);
                kendo.alert(response.message);
              },
              complete: function(jqXHR, textStatus) {
                dialog.close();
              }
            });
          },
          primary: true,
          text: "Wijzig"
        }
      ],
      content: kendo.template($('#edit-answer-dialog-template').html()),
      visible: false,
      title: 'Wijzig Antwoord',
      initOpen: function() {
        $('#answer_answer_text').val(rowData.answer_text);
      }
    });
    const dialog = $('#edit-answer-dialog').data('kendoDialog');
    dialog.open();
  }

  destroyAnswer(event) {
    event.preventDefault();
    const grid = this.element.data('kendoGrid');
    const rowData = this.dataItem($(event.target).closest("tr"));
    kendo.confirm(`Ben je zeker dat je dit antwoord wil <strong>verwijderen</strong>?`).done(function () {
      $.ajax({
        dataType: 'json',
        type: 'DELETE',
        url: `${rowData.uri}.json`,
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        success: function (data, textStatus, jqXHR) {
          // grid.removeRow(`tr[data-uid='${rowData.uid}']`);
          grid.dataSource.fetch();
        },
        error: function (jqXHR, textStatus, errorThrown) {
          const response = JSON.parse(jqXHR.responseText);
          kendo.alert(response.message);
        }
      });
    });
  }

  addEditCommand() {
    this.addActionsColumn();
    this.gridConfig.columns[this.actionColumnIndex].width += this.commandButtonWidth;
    this.gridConfig.columns[this.actionColumnIndex].command.push({
      name: "edit-button",
      text: " ",
      iconClass: "k-icon k-i-pencil",
      click: this.editAnswer,
      visible: function(rowData) { return rowData.can_edit; }
    });
  }

  addDestroyCommand() {
    this.addActionsColumn();
    this.gridConfig.columns[this.actionColumnIndex].width += this.commandButtonWidth;
    this.gridConfig.columns[this.actionColumnIndex].command.push({
      name: "destroy-button",
      text: " ",
      iconClass: "k-icon k-i-trash",
      click: this.destroyAnswer,
      visible: function(rowData) { return rowData.can_destroy; }
    });
  }

  addActionsColumn() {
    if (typeof this.actionColumnIndex == 'number') return;
    this.gridConfig.columns.push({
      title: 'Acties',
      command: [],
      width: 0
    });
    this.actionColumnIndex = this.gridConfig.columns.length - 1;
  }
}
